exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alzheimers-disease-js": () => import("./../../../src/pages/alzheimers-disease.js" /* webpackChunkName: "component---src-pages-alzheimers-disease-js" */),
  "component---src-pages-athletics-js": () => import("./../../../src/pages/athletics.js" /* webpackChunkName: "component---src-pages-athletics-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-health-js": () => import("./../../../src/pages/health.js" /* webpackChunkName: "component---src-pages-health-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-js": () => import("./../../../src/pages/life.js" /* webpackChunkName: "component---src-pages-life-js" */),
  "component---src-pages-mental-health-js": () => import("./../../../src/pages/mental-health.js" /* webpackChunkName: "component---src-pages-mental-health-js" */),
  "component---src-pages-neuromarketing-js": () => import("./../../../src/pages/neuromarketing.js" /* webpackChunkName: "component---src-pages-neuromarketing-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-podcasts-js": () => import("./../../../src/pages/news/podcasts.js" /* webpackChunkName: "component---src-pages-news-podcasts-js" */),
  "component---src-pages-news-videos-js": () => import("./../../../src/pages/news/videos.js" /* webpackChunkName: "component---src-pages-news-videos-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-templates-blog-single-js": () => import("./../../../src/templates/blog-single.js" /* webpackChunkName: "component---src-templates-blog-single-js" */),
  "component---src-templates-category-single-js": () => import("./../../../src/templates/category-single.js" /* webpackChunkName: "component---src-templates-category-single-js" */)
}

